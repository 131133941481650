<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userCandidateData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No nhr found with this id. Check
        <b-link class="alert-link" :to="{ name: 'all-nhr-list' }">
          nhr List
        </b-link>
      </div>
    </b-alert>

    <template v-if="userCandidateData">
      <!-- Add Candidate form -->
      <candidate-add
        :interview-id.sync="nhrId"
        :user-data.sync="dataLocal"
        :is-add-new-candidate-sidebar-active.sync="
          isAddNewCandidateSidebarActive
        "
        :candidates-options.sync="candidatesList"
        @refetch-data="refetchCandidatesList"
      />

      <!-- Add Interview Form -->
      <interview-add
        :is-add-new-interview-sidebar-active.sync="
          isAddNewInterviewSidebarActive
        "
        :candidate.sync="candidate"
        @refetch-data="fetchStatus"
      />

      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <n-h-r-view-info :user-data="userCandidateData" />
        </b-col>
      </b-row>
      <b-card>
        <b-tabs>
          <b-tab active title="NHR Status/Timeline">
            <b-card-text>
              <n-h-r-view-timeline
                :history-data="userCandidateData.histories"
              />
            </b-card-text>
          </b-tab>
          <b-tab title="Assigned Candidates">
            <b-card-text>
              <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                  <b-form-group>
                    <div class="d-flex align-items-center">
                      <b-button
                        class="btn add-btn mr-2"
                        variant="outline-primary"
                        @click="addCandidates"
                        v-if="$can('Zircly Admin') || $can('Recruiter')"
                        >Add / Remove Candidate</b-button
                      >
                      <label class="mr-1">Search</label>
                      <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                      />
                    </div>
                  </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table
                  :columns="columns"
                  :rows="userCandidateData.candidates"
                  :rtl="direction"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                  }"
                  :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                  }"
                >
                  <template slot="table-row" slot-scope="props">
                    <!-- Column: Action -->
                    <span v-if="props.column.field === 'action'">
                      <span>
                        <feather-icon
                          v-b-tooltip.hover.top="'View Candidate'"
                          class="btn-link cursor-pointer mx-50"
                          icon="EyeIcon"
                          size="16"
                          @click="viewCandidateNHR(props.row.hashid)"
                        />
                        <feather-icon
                          v-b-tooltip.hover.top="'Add Interview'"
                          class="btn-link cursor-pointer mx-50"
                          icon="PlusIcon"
                          size="16"
                          @click="addInterview(props.row)"
                        />

                        <feather-icon
                          v-b-tooltip.hover.top="'Resume'"
                          v-if="props.row.resume_file_path"
                          class="btn-link cursor-pointer mx-50"
                          icon="FileTextIcon"
                          size="16"
                          @click.stop.prevent="
                            openWindow(props.row.resume_file_path)
                          "
                        />
                        <!-- <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item
                            @click="viewCandidateNHR(props.row.hashid)"
                          >
                            <feather-icon icon="EyeIcon" class="mr-50" />
                            <span>View</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="addInterview(props.row)">
                            <feather-icon icon="PlusIcon" class="mr-50" />
                            <span>Add Interview</span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            @click.stop.prevent="
                              openWindow(props.row.resume_file_path)
                            "
                          >
                            <feather-icon icon="FileTextIcon" class="mr-50" />
                            <span>Resume</span>
                          </b-dropdown-item>
                        </b-dropdown> -->
                      </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Showing 1 to </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['3', '5', '10']"
                          class="mx-1"
                          @input="
                            (value) =>
                              props.perPageChanged({ currentPerPage: value })
                          "
                        />
                        <span class="text-nowrap">
                          of {{ props.total }} entries
                        </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="
                            (value) => props.pageChanged({ currentPage: value })
                          "
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Assigned Employees">
            <b-card-text>
              <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                  <b-form-group>
                    <div class="d-flex align-items-center">
                      <!-- <b-button class="btn add-btn mr-2" @click="addCandidates"
                        >Add Recruiter</b-button
                      > -->
                      <label class="mr-1">Search</label>
                      <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                      />
                    </div>
                  </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table
                  :columns="columnsEmployee"
                  :rows="userCandidateData.employees"
                  :rtl="direction"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                  }"
                  :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                  }"
                >
                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Showing 1 to </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['3', '5', '10']"
                          class="mx-1"
                          @input="
                            (value) =>
                              props.perPageChanged({ currentPerPage: value })
                          "
                        />
                        <span class="text-nowrap">
                          of {{ props.total }} entries
                        </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="
                            (value) => props.pageChanged({ currentPage: value })
                          "
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { onUnmounted, ref } from "@vue/composition-api";
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BButton,
  BCardText,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import nhrStoreModule from "../../nhrStoreModule";
import NHRViewInfo from "./NHRViewInfo.vue";
import NHRViewTimeline from "./NHRViewTimeline.vue";
import CandidateAdd from "../list/CandidateAdd";
import RecruiterAdd from "../list/RecruiterAdd.vue";
import InterviewAdd from "@/views/interviews/list/InterviewAdd.vue";

export default {
  components: {
    BPagination,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    CandidateAdd,
    RecruiterAdd,
    NHRViewInfo,
    NHRViewTimeline,
    VueGoodTable,
    InterviewAdd,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const userCandidateData = ref(null);
    const NHR_APP_STORE_MODULE_NAME = "app-nhr";

    // Register module
    if (!store.hasModule(NHR_APP_STORE_MODULE_NAME)) {
      store.registerModule(NHR_APP_STORE_MODULE_NAME, nhrStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(NHR_APP_STORE_MODULE_NAME))
        store.unregisterModule(NHR_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-nhr/fetchNHR", router.currentRoute.params.id)
      .then((response) => {
        userCandidateData.value = response.data.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userCandidateData.value = undefined;
        }
      });
    const refetchCandidatesList = () => {
      store
        .dispatch("app-nhr/fetchNHR", router.currentRoute.params.id)
        .then((response) => {
          userCandidateData.value = response.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userCandidateData.value = undefined;
          }
        });
    };
    return {
      userCandidateData,
      refetchCandidatesList,
    };
  },
  data() {
    return {
      nhrId: this.$route.params.id,
      pageLength: 3,
      pageLengthEmployee: 3,
      isAddNewCandidateSidebarActive: false,
      isAddNewInterviewSidebarActive: false,
      dir: false,
      candidate: {},
      dataLocal: {
        candidates: [],
      },
      candidatesList: [],
      recruiterList: [],
      columns: [
        {
          label: "Candidate Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Years of Experience",
          field: "years_of_experience",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      columnsEmployee: [
        {
          label: "Employee Name",
          field: "name",
        },
        {
          label: "Employee Email",
          field: "email",
        },
        {
          label: "Employee Id",
          field: "employee_id",
        },
        {
          label: "Designation",
          field: "designation",
        },
      ],
      searchTerm: "",
      searchTermEmployee: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    addCandidates() {
      const self = this;
      this.$store
        .dispatch("app-nhr/fetchNHR", this.nhrId)
        .then((res) => {
          this.$store.dispatch("app-nhr/fetchCandidates").then((candidates) => {
            self.candidatesList = candidates;
            self.dataLocal.candidates = [];
            res.data.data.candidates.forEach((item) => {
              self.dataLocal.candidates.push(item.hashid);
            });
            self.isAddNewCandidateSidebarActive = true;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addInterview(item) {
      this.candidate.name = item.name;
      this.candidate.id = item.hashid;
      this.isAddNewInterviewSidebarActive = true;
    },
    addRecruiter() {
      const self = this;
      this.$store
        .dispatch("app-nhr/fetchNHR", this.nhrId)
        .then((res) => {
          console.log(res.data.data);
          this.$store.dispatch("app-nhr/fetchRecruiters").then((recruiters) => {
            self.recruiterList = recruiters;
            self.dataLocal.recruiters = [];
            res.data.data.recruiters.forEach((item) => {
              self.dataLocal.recruiters.push(item.hashid);
            });
            self.isAddNewCandidateSidebarActive = true;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
    viewCandidateNHR(id) {
      this.$router.push(`/view/candidate/${id}`).catch(() => {});
    },
    fetchStatus() {
      console.log("interview added");
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss" scoped>
.add-btn {
  border: solid 1px;
  width: 100%;
}
</style>